global.jQuery = require('jquery');
const $ = global.jQuery;
require('venobox');

const vbOpts = {
    overlayColor: 'rgba(245,245,245,0.85)',
    closeBackground: '#161616',
};

class Carousel {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.interval = 5000;

        this.$primaryLinkNode = this.$node.find('[data-ref="primary-link"]');
        this.$primaryImageNode = this.$node.find('[data-ref="primary-image"]');
        this.$secondaryLinkNodes = this.$node.find('[data-ref="secondary-link"]');
        this.$secondaryImageNodes = this.$node.find('[data-ref="secondary-image"]');

        this.$primaryLinkNode.on('click', this.handlePrimaryLinkClick.bind(this));
        this.$primaryLinkNode.on('mouseenter', this.handleMouseEnter.bind(this));
        this.$primaryLinkNode.on('mouseleave', this.handleMouseLeave.bind(this));
        this.$secondaryLinkNodes.on('click', this.handleSecondaryLinkClick.bind(this));

        this.start();
    }

    handlePrimaryLinkClick (event) {
        event.preventDefault();

        // Due to navigation issues with venobox, build a virtual collection of
        // anchor elements and supply them to venobox's init, manually
        // triggering the one that would match the currently selected main image
        const $virtualLinkNodes = this.$secondaryLinkNodes.map((i, elem) => {
            const $linkNode = $(elem);
            const $virtualLinkNode = $('<a>Link</a>').attr('href', $linkNode.attr('href'));
            return $virtualLinkNode.get(0);
        });
        $virtualLinkNodes.venobox({
            ...vbOpts,
            gallItems: $virtualLinkNodes,
        });
        $virtualLinkNodes.filter(
            // XXX: Regular attr selector isn't hardy enough for this; we have
            // to compare directly to find the one to trigger
            (i, elem) => ($(elem).attr('href') === this.$primaryLinkNode.attr('href'))
        ).trigger('click');
    }

    handleSecondaryLinkClick (event) {
        event.preventDefault();

        const $linkNode = $(event.currentTarget);
        const $imageNode = $linkNode.find(this.$secondaryImageNodes);
        this.$primaryLinkNode.attr({
            'href': $linkNode.attr('href'),
        });
        this.$primaryImageNode.attr({
            'srcset': $imageNode.attr('srcset'),
            'src': $imageNode.attr('src'),
            'alt': $imageNode.attr('alt'),
        });

        this.$secondaryLinkNodes.filter('[aria-current="true"]').removeAttr('aria-current');
        $linkNode.attr('aria-current', 'true');

        this.restart();
    }

    handleMouseEnter () {
        this.stop();
    }

    handleMouseLeave () {
        this.start();
    }

    next () {
        const $visibleNode = this.$secondaryLinkNodes.filter('[aria-current="true"]');
        const i = this.$secondaryLinkNodes.index($visibleNode);
        const next = i === this.$secondaryLinkNodes.length - 1 ? 0 : i + 1;
        const $nextNode = this.$secondaryLinkNodes.eq(next);
        $nextNode.trigger('click');
    }

    restart () {
        this.stop();
        this.start();
    }

    start () {
        this.timer = window.setInterval(this.next.bind(this), this.interval);
    }

    stop () {
        window.clearInterval(this.timer);
    }
}

module.exports = Carousel;
