const $ = require('jquery');

class Share {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.$linkNodes = this.$node.find('[data-ref="link"]');
        this.$linkNodes.on('click', this.handleLinkClick.bind(this));
    }

    handleLinkClick (event) {
        event && event.preventDefault();
        const $linkNode = $(event.currentTarget);
        window.open($linkNode.attr('href'),
                    '_blank',
                    'width=720,height=570,status=no,location=no');
    }
}

module.exports = Share;
