const $ = require('jquery');

class Header {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.$navNode = this.$node.find('[data-ref="nav"]');
        this.$navToggleNode = this.$node.find('[data-ref="toggle"]');

        this.$navToggleNode.on('click', this.handleNavToggleClick.bind(this));
    }

    handleNavToggleClick () {
        if (this.$navToggleNode.attr('aria-expanded') === 'false') {
            this.showNav();
        } else {
            this.hideNav();
        }
    }

    hideNav () {
        this.$navNode.attr('aria-expanded', 'false');
        this.$navToggleNode.attr('aria-expanded', 'false');
    }

    showNav () {
        this.$navNode.attr('aria-expanded', 'true');
        this.$navToggleNode.attr('aria-expanded', 'true');
    }
}

module.exports = Header;
