const $ = require('jquery');

class HomeCarousel {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;
        this.$slideNodes = this.$node.find('[data-ref="slide"]');
        this.interval = 8000;
        this.start();
    }

    nextSlide () {
        const $visibleNode = this.$slideNodes.filter('.is-visible');
        const i = this.$slideNodes.index($visibleNode);
        const next = i === this.$slideNodes.length - 1 ? 0 : i + 1;
        const $nextNode = this.$slideNodes.eq(next);
        $visibleNode.removeClass('is-visible');
        $nextNode.addClass('is-visible');
    }

    start () {
        this.timer = window.setInterval(this.nextSlide.bind(this), this.interval);
    }
}

module.exports = HomeCarousel;
