global.jQuery = require('jquery');
const $ = global.jQuery;

// Mark touch event listeners as passive
// https://stackoverflow.com/a/62177358/258794
$.event.special.touchstart = {
    setup: function (_, ns, handle) {
        this.addEventListener('touchstart', handle, { passive: !ns.includes('noPreventDefault') });
    },
};
$.event.special.touchmove = {
    setup: function (_, ns, handle) {
        this.addEventListener('touchmove', handle, { passive: !ns.includes('noPreventDefault') });
    },
};
$.event.special.touchend = {
    setup: function (_, ns, handle) {
        this.addEventListener('touchend', handle, { passive: !ns.includes('noPreventDefault') });
    },
};

// Import components
const components = {
    carousel: require('./components/carousel'),
    header: require('./components/header'),
    'home-carousel': require('./components/home-carousel'),
    'newsletter-toast': require('./components/newsletter-toast'),
    share: require('./components/share'),
};

// Bind components
const initComponents = (components, rootNode = document.body) => {
    $(rootNode)
        .find('[data-component]')
        .each((i, node) => {
            $(node)
                .data('component')
                .split(/\s+/)
                .forEach((componentName) => {
                    const component = components[componentName];
                    if (component) new component(node);
                });
        });
};

$(() => {
    initComponents(components);
});
