const $ = require('jquery');
const Cookies = require('js-cookie');

class NewsletterToast {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.cookieName = 'jamesiles_newsletter_toast_dismissed';
        this.cookieExpiry = 30;

        this.$closeButtonNode = this.$node.find('[data-ref="close"]');
        this.$closeButtonNode.on('click', this.handleCloseButtonClick.bind(this));
        this.boundhandleDocumentKeyUp = this.handleDocumentKeyUp.bind(this);

        this.showToastIfAppropriate();
    }

    open () {
        this.$node.addClass('is-open');
        $(document).on('keyup', this.boundhandleDocumentKeyUp);
    }

    close () {
        Cookies.set(this.cookieName, 'yes', {
            expires: this.cookieExpiry,
            secure: (window.location.protocol === 'https')
        });

        this.$node.removeClass('is-open');
        $(document).off('keyup', this.boundhandleDocumentKeyUp);
    }

    handleCloseButtonClick () {
        this.close();
    }

    handleDocumentKeyUp (event) {
        if (event.key === 'Escape') {
            this.close();
        }
    }

    showToastIfAppropriate () {
        const cookieValue = Cookies.get(this.cookieName);
        if (this.$node.length && typeof cookieValue === 'undefined') {
            this.open();
        }
    }
}

module.exports = NewsletterToast;
